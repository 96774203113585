export enum SocketEvents {
  ServerRestarted = 'ServerRestarted',
  Unauthenticated = 'Unauthenticated',
  Authenticated = 'Authenticated',
  RegistrationFailed = 'RegistrationFailed',
  NewPicturesGenerated = 'NewPictures',
  Logout = 'Logout',
  ModelTrainingProgress = 'ModelTrainingProgress',
  ModelTrainingCompleted = 'ModelTrainingCompleted',
  ModelTrainingFailed = 'ModelTrainingFailed',
  Connect = 'connect',
  ConnectError = 'connect_error',
  Disconnect = 'disconnect',
  UserBalanceChanged = 'UserBalanceChanged',
  ImageGenerationJobCounterChanged = 'ImageGenerationJobCounterChanged',
  SubmitContactForm = 'SubmitContactForm',
  UpdateUserPassword = 'updateUserPassword',
  R2ImageUploadProgress = 'R2ImageUploadProgress',
  ProcessApplePayment = 'ProcessApplePayment',
}

export enum SocketEmits {
  LoginCredentials = 'LoginCredentials',
  LoginApple = 'LoginApple',
  Logout = 'Logout',
  GetPicturesData = 'GetPicturesData',
  GetPicturesDataByUserChosenUserId = 'GetPicturesDataByUserChosenUserId',
  GetPicturesCountFromPortraitSet = 'GetPicturesCountFromPortraitSet',
  GetPicturesQueue = 'GetPicturesQueue',
  DeleteNewPictures = 'DeleteNewPictures',
  DeletePictures = 'DeletePictures',
  DeletePictureSet = 'DeletePictureSet',
  GetPictureSetsData = 'GetPictureSetsData',
  GetPictureSetsDataWithIds = 'GetPictureSetsDataWithIds',
  UpdatePictureSet = 'UpdatePictureSet',
  GetPictureSetsInTraining = 'GetPictureSetsInTraining',
  GetCategories = 'GetCategories',
  GetPortraitSetsData = 'GetPortraitSetsData',
  GetPromptText = 'GetPromptText',
  GetPortraitSetsDataByIdWithUserDefaultOptions = 'GetPortraitSetsDataByIdWithUserDefaultOptions',
  GetPortraitSetsDataWithCounts = 'GetPortraitSetsDataWithCounts',
  GetLatestPictures = 'GetLatestPictures',
  GetLastNPictures = 'GetLastNPictures',
  UpdateUser = 'UpdateUser',
  DeleteUser = 'DeleteUser',
  UpdateUserPassword = 'UpdateUserPassword',
  UpdateNotificationSettings = 'UpdateNotificationSettings',
  UpdateLanguageSetting = 'UpdateLanguageSetting',
  GetNotificationSettings = 'GetNotificationSettings',
  GetUserCoins = 'GetUserCoins',
  GetCoinPackages = 'GetCoinPackages',
  GetCoinPackagesForUser = 'GetCoinPackagesForUser',
  DecreaseDownload = 'DecreaseDownload',
  GeneratePictures = 'GeneratePictures',
  DeletePortraitSetDefaults = 'DeletePortraitSetDefaults',
  SavePortraitSetDefaults = 'SavePortraitSetDefaults',
  StartTestTraining = 'StartTestTraining',
  SubmitContactForm = 'SubmitContactForm',
  BuyCoinPackage = 'BuyCoinPackage',
  SaveDeviceToken = 'SaveDeviceToken',
  DeleteDeviceToken = 'DeleteDeviceToken',
  ProcessApplePayment = 'ProcessApplePayment',
}
