import { atom } from 'jotai';
import { UploadedImage } from '../types/upload.types';
import { atomWithReset, atomWithStorage } from 'jotai/utils';
import { PictureTypes } from '@api-types';

export const reloadPictureSetAtom = atom<boolean>(false);
export const readyPictureSetsAtom = atomWithReset<PictureTypes.PictureSetViewModel[]>([]);
export const selectedPictureSetAtom = atomWithReset<PictureTypes.PictureSetViewModel | null>(null);
export const selectedPictureSetInProfileAtom = atomWithReset<PictureTypes.PictureSetViewModel | null>(null);
export const selectedPreviewGenderAtom = atomWithReset<string | null>(null);

export const customBreadcrumbPageNameAtom = atom<string | null>(null);
export const inTrainingPictureSetsAtom = atomWithReset<PictureTypes.PictureSetViewModel[]>([]);

// Help Popover
export const openPopupAtom = atom<boolean>(false);

// Uploader
export const uploadImagesAtom = atom<UploadedImage[]>([]);

// PictureSet
export const openGenderDialogAtom = atom<boolean>(false);

// Dialog
export const pictureSetConfirmDeleteDialogAtom = atom(false);

// Training
export const trainingProgressAtom = atom(0);
export const inTrainingAtom = atomWithReset(false);
export const trainingErrorAtom = atom(false);
export const trainingReadyAtom = atom(false);

// UploadManager
export const uploadProgressAtom = atom(0);
export const processingProgressAtom = atom(0);
export const isImageProcessingAtom = atom(false);
export const isUploadingAtom = atom(false);
export const isProcessingCompleteAtom = atom(false);
export const uploadIsSavingAtom = atom(false);
export const saveUploadedImagesAtom = atom(false);
