import React from 'react';
import { Box, Typography, Modal, Button, useTheme } from '@mui/material';
import { atom, useAtom } from 'jotai';
import { keyframes } from '@emotion/react';
import useLocales from '@/locales/useLocales';

// Atom to manage the state of the update message modal
export const updateMessageAtom = atom({
  open: false,
});

const pulse = keyframes`
  0%, 100% {
    opacity: 0.5;
    transform: scale(0.8);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
`;

const FullscreenUpdateMessage: React.FC = () => {
  const [updateMessage, setUpdateMessage] = useAtom(updateMessageAtom);
  const { translate } = useLocales();

  // Function to handle closing the modal
  const handleClose = () => {
    setUpdateMessage({ ...updateMessage, open: false });
  };

  const theme = useTheme();

  return (
    <Modal open={updateMessage.open} onClose={handleClose}>
      <Box
        position="fixed"
        top={0}
        left={0}
        width="100%"
        height="100%"
        bgcolor="rgba(0, 0, 0, 1)"
        zIndex={9999}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
        }}
      >
        <Box sx={{ position: 'relative', marginTop: theme.spacing(-20) }}>
          <Typography variant="h3" gutterBottom>
            {translate('restartServer.message')}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            {[0, 1, 2].map((index) => (
              <Box
                key={index}
                sx={{
                  width: 10,
                  height: 10,
                  borderRadius: '50%',
                  backgroundColor: 'primary.main',
                  mx: 0.5,
                  animation: `${pulse} 1.4s ease-in-out ${index * 0.2}s infinite`,
                }}
              />
            ))}
          </Box>
          <Button variant="contained" color="primary" size="large" onClick={() => window.location.reload()} sx={{ mt: 3 }}>
            {translate('restartServer.reload')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default FullscreenUpdateMessage;
