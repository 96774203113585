import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

export const maxAppHeightState = atomWithStorage('maxHeight', 0);
export const maxAppItemsAtom = atomWithStorage('maxItems', 0);
export const appShowAllAtom = atomWithStorage('showAll', [] as string[]);
export const appScrollPositionAtom = atom<{ [category: string]: number }>({});
export const loadSupportBeaconScriptAtom = atom(false);
export const showSupportBeaconAtom = atom(false);
export const completedToursAtom = atomWithStorage<string[]>('completedTours', []);
export const userDownloadsAtom = atom<number | null>(0);
export const lastInfoMessageTimeAtom = atomWithStorage<number | null>('lastInfoMessageTime', null);
