import { BaseApi, TranslationMapping } from './_baseApi';
import { AuthTypes, HttpErrorCodes, UserTypes } from '@api-types';

export class AuthApi extends BaseApi {
  constructor(setSnackbarQueue: any, translate: (text: any, options?: any) => string, token: string | null) {
    super(setSnackbarQueue, translate, token);
  }

  public loginWithCredentials = async (credentials: AuthTypes.LoginRequest): Promise<UserTypes.AuthenticatedUserViewModel> => {
    const codeMappings: TranslationMapping = {
      406: 'userLogin.errors.invalidCredentials',
      404: 'userLogin.errors.invalidCredentials',
      403: 'userLogin.errors.accountNotActivated',
      default: 'userLogin.errors.loginFailed',
    };
    return await this.post<UserTypes.AuthenticatedUserViewModel>('/auth/login/credentials', credentials, codeMappings);
  };

  public loginAsGuest = async (guestUserId: string): Promise<UserTypes.AuthenticatedUserViewModel> => {
    const codeMappings: TranslationMapping = {
      403: 'userLogin.errors.guestEmailExists', // Add specific error code mapping
      default: 'userLogin.errors.loginFailed',
    };
    return await this.post<UserTypes.AuthenticatedUserViewModel>('/auth/login/guest', { guestUserId }, codeMappings);
  };

  public refreshAccessToken = async (request: AuthTypes.RefreshTokenRequest): Promise<UserTypes.AuthenticatedUserViewModel> => {
    const codeMappings: TranslationMapping = {
      default: 'userLogin.errors.loginFailed',
    };
    return await this.post<UserTypes.AuthenticatedUserViewModel>('/auth/refreshtoken', request, codeMappings);
  };

  public logout = async (): Promise<void> => {
    await this.postVoid('/auth/logout');
  };

  public passwordResetRequestEmail = async (credentials: AuthTypes.ResetPasswordEmailRequest): Promise<void> => {
    await this.postVoid('/auth/password-reset/send-email', credentials);
  };
  public passwordResetSubmitPassword = async (credentials: AuthTypes.ResetPasswordRequest): Promise<void> => {
    const codeMappings: TranslationMapping = {
      404: 'resetPassword.errors.notFound',
      500: 'errors.500',
      [HttpErrorCodes.ResetPasswordTokenExpired]: 'resetPassword.errors.codeExpired',
      [HttpErrorCodes.ResetPasswordTokenInvalid]: 'resetPassword.errors.invalidCode',
      default: 'resetPassword.errors.passwordResetFailed',
    };
    await this.postVoid('/auth/password-reset', credentials, codeMappings);
  };
}
